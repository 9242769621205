'use client'
import OrganizationSelector from '@/components/ui/OrganizationSelector';
import { useAuth } from '@clerk/nextjs';
import Welcome from './welcome/_components/Welcome';

const Home = () => {
    const { isSignedIn} = useAuth();


    return (
         isSignedIn ? <OrganizationSelector /> : <Welcome />
    );
};

export default Home;